<template>
  <v-container class="text-left px-2" id="plan-view">

    <v-card class="glass">
      <!--  <v-card-title v-show="selected === null">
        Rutinas
      </v-card-title> -->
      <v-card-text class="px-0">
        <v-alert prominent text type="info" v-if="info" @input="alert = false" class="ma-4">
          {{ info }}
        </v-alert>

        <v-scroll-x-transition mode="in-out" hide-on-leave>
          <v-chip-group multiple v-model="filterTags" class="pl-2" key="chips" v-show="!loading && selected == null">
            <v-chip color="secondary" :value="reducedTag" filter v-for="reducedTag in reducedTags" :key="reducedTag"
              @click="selected = null">

              {{ reducedTag }}
            </v-chip>
          </v-chip-group>
        </v-scroll-x-transition>

        <v-scroll-x-transition group mode="in-out" hide-on-leave>
          <v-list :key="'list'" class="ma-1 py-0 rounded-lg overflow-hidden glass"
            v-show="!loading && selected == null">
            <template v-for="(planElem, iterIndex) in filteredRoutines">
              <v-list-item three-line @click="selectPlan(iterIndex, planElem)"
                :style="{ 'border-left': '2px solid ' + planElem.color + ' !important' }" :key="iterIndex">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ planElem.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ planElem.description }}</v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip-group multiple mandatory>
                      <v-chip v-for="(type, index) in planElem.tag" :key="index" x-small>
                        {{ type }}
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="user.planification == planElem.id">
                  <v-chip small color="primary" v-if="!workedOut" class="rounded-lg">
                    <v-icon left>mdi-chevron-right</v-icon>
                    Principal
                  </v-chip>
                  <v-chip small v-if="workedOut" class=" glass rounded-lg">
                    <v-icon left>mdi-check</v-icon>
                    Entrenado
                  </v-chip>

                </v-list-item-action>




              </v-list-item>
              <v-divider
                v-show="filterTags.length == 0 || planElem.tag.some(tag => filterTags.includes(tag)) && iterIndex < allRoutines.length - 1"></v-divider>
            </template>



          </v-list>

          <v-skeleton-loader key="skel" type="list-item-two-line" v-show="loading"
            class="px-2 py-1 pt-2"></v-skeleton-loader>


          <v-card style="background: rgba(255, 255, 255, 0.04);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.8px);
-webkit-backdrop-filter: blur(5.8px);
border: 1px solid rgba(255, 255, 255, 0.05);" v-if="!loading && !info" class="ma-2"
            v-for="(planElem, iterIndex) in allRoutines" :key="iterIndex + 'rand'"
            :style="{ 'border-left': '2px solid ' + planElem.color + ' !important' }"
            :id="planElem.name.replace(/\s/g, '-')" v-show="selected === iterIndex && !loading">

            <v-card-title class="pb-0" v-if="iterIndex == selected">
              <v-spacer></v-spacer>
              <v-btn @click="selected = null" text small v-if="allRoutines.length > 1">
                <v-icon left>
                  mdi-chevron-up
                </v-icon>
                Ver menos
              </v-btn>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-title id="v-step-0">
              <!--         <v-avatar v-if="planElem.color" :color="planElem.color" size="20" class="mr-2"></v-avatar>
 --> {{ planElem.name }}

              <template v-if="planElem.type.includes('matrix') && user.planification == planElem.id">
                <v-divider class="mx-2"></v-divider>
                <span class="caption" id="v-step-1">Dia {{ workoutsCompleted }} / Sem {{ planificationWeek + 1 }}</span>
              </template>

              <!--    <template v-if="iterIndex == selected">
                            <v-spacer></v-spacer>

              <v-btn @click="selected = null" fab icon small>
                  <v-icon>
                      mdi-chevron-up
                  </v-icon>
                </v-btn>

              </template> -->


            </v-card-title>
            <v-card-text class="ma-0 pa-0">
              <p id="v-step-3" class="px-4">
                {{ planElem.description }}
              </p>
              <v-divider></v-divider>



              <v-alert type="success" text prominent
                v-if="workedOut && selected == 0 && planElem.type.includes('matrix')">
                ¡Excelente! Has completado tu entrenamiento de hoy.
                Recuperate y continúa mañana.
              </v-alert>


              <Matrix v-if="selected == 0 && planElem.type.includes('matrix')" ref="matrix" :planElem="planElem"
                :planificationweek="planificationWeek" :workoutsCompleted="workoutsCompleted" :userPlan="user.plan" />


              <Matrix v-if="selected && planElem.type.includes('matrix')" ref="matrix" :planElem="planElem"
                :userPlan="user.plan" />

              <Simple v-if="selected != null && planElem.type == 'listado'" :exercises="planElem.bloques"
                ref="simple" />

              <v-card-text v-if="selected != null && planElem.type == 'text'" v-html="planElem.bloques">
              </v-card-text>

              <!--    <v-card-actions v-if="!workedOut && user && user.planification &&  user.planification == planElem.id" >
            <v-spacer></v-spacer>
            <v-btn @click="finishDailyWorkout" class="glass" :loading="loadingFinish"> 
              <v-icon left>
                mdi-check
              </v-icon>
              Completar entrenamiento
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions> -->



            </v-card-text>

            <v-card-actions v-show="iterIndex != selected">
              <v-btn block elevation="0" @click="selectPlan(iterIndex, planElem)">
                Ver
              </v-btn>

            </v-card-actions>
          </v-card>



        </v-scroll-x-transition>
      </v-card-text>
    </v-card>












    <v-tour name="myTour" :steps="steps" :options="myOptions">
      <template slot-scope="tour">
        <v-fade-transition>
          <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip"
            :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
            <template>
              <div slot="actions">
                <v-btn class="mr-2" small @click="skipTour" v-if="tour.isFirst">Saltar intro</v-btn>

                <v-btn class="mr-2" @click="tour.previousStep" v-if="tour.currentStep > 0">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn @click="tour.nextStep" color="primary" v-if="!tour.isLast">
                  <v-icon v-if="tour.isLast" left>mdi-check</v-icon>
                  <v-icon v-else left>mdi-chevron-right</v-icon>
                  Siguiente</v-btn>

                <v-btn @click="stopTour" v-if="tour.isLast" color="success">
                  <v-icon left>mdi-check</v-icon>
                  Finalizar</v-btn>
              </div>
            </template>
          </v-step>
        </v-fade-transition>
      </template>
    </v-tour>

  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore";

import moment from 'moment'

import ExerciseListItem from "@/components/planifications/view/ExerciseListItem.vue";
import Simple from "@/components/planifications/view/Simple.vue";

import Matrix from "@/components/planifications/view/Matrix.vue";
import { orderBy, limit } from 'firebase/firestore';



export default {
  props: {
    UserObject: {
      type: Object,
      required: false
    }
  },
  components: {
    ExerciseListItem, Simple, Matrix
  },
  name: 'PlanView',
  data() {
    return {
      filterTags: [],
      selected: null,
      loadingFinish: false,
      activeTab: 0,
      info: null,
      myOptions: {
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'Saltear intro',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar',
        }
      },
      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood

          content: `En esta sección podrás ver tu el dia de tu planificación de entrenamiento en curso. <br><br> <strong>¡Vamos a verla!</strong> `,
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {

            this.activeTab = this.workoutsCompleted - 1
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-1',
          content: 'Aqui podrás ver el día de la semana y la semana de la planificación que estás siguiendo. <br><br> Para avanzar en la planificación es estrictamente necesario registrar asistencia al concurrir al centro.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-3',
          content: 'Aquí podrás ver la descripción de tu planificación.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-bloque-1',
          content: 'Aquí podrás ver los bloques de tu plan y su descripción.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-metodo-1',
          content: 'Aquí podrás ver el método a utilizar en este bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-ejercicios-1',
          content: 'Aquí podrás ver los ejercicios del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-cuant-1',
          content: 'Aquí podrás ver la cuantificación del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        },
        {
          target: '#v-step-rpe-1',
          content: 'Aquí podrás ver el RPE del bloque.',
          params: {
            placement: 'bottom',
            highlight: true
          },
          before: type => new Promise((resolve, reject) => {
            this.$nextTick(() => {
              this.$vuetify.goTo(0)
              setTimeout(() => {
                resolve()
              }, 300)
            })

          })
        }



      ],
      loading: true,
      user: {},
      workoutDoc: {},
      workoutsCompleted: 0,
      workoutPlan: {},
      alert: false,
      hideFinish: false,
      showActions: false,
      planificationWeek: null,
      emptyWorkoutDocs: false,
      workedOut: false,
      allRoutines: [],
      reducedTags: []

    }
  },
  methods: {
    stopTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].stop()
    },
    skipTour() {
      this.$store.commit('SET_PLANIFICATION_INTRO_SEEN', true)
      this.$tours['myTour'].skip()
    },
    rpeColor(value) {
      value = parseInt(value)
      if (value === 1) return 'green lighten-2';
      else if (value === 2) return 'green lighten-1';
      else if (value === 3) return 'green ';
      else if (value === 4) return 'green darken-1';
      else if (value == 5) return 'green darken-2';
      else if (value === 6) return 'yellow darken-2';
      else if (value === 7) return 'orange';
      else if (value === 8) return 'orange darken-3';
      else if (value === 9) return 'red';
      else if (value === 10) return 'red darken-3';
    },
    async getUser() {
      let data;
      if (this.$props.UserObject) {
        data = this.$props.UserObject
      } else {
        const db = getFirestore()
        let user = this.$store.state.Auth.token.claims.user_id
        const docRef = doc(db, `users/${user}`);
        const docSnap = await getDoc(docRef);
        data = docSnap.data()
      }

      const db = getFirestore()
      const paymentsRef = collection(db, `payments`);
      const paymentsQuery = query(
        paymentsRef,
        where("id", "==", data.id),
        where("endOfSubscription", ">", new Date()), // Filter by future dates
        orderBy("endOfSubscription", "asc"),
        limit(1)
      );
      const paymentDocs = await getDocs(paymentsQuery);

      if (!paymentDocs.empty) {
        const lastPayment = paymentDocs.docs[0].data();
        data.plan = lastPayment.amount;
      } else {
      }

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          await this.setAlert('user_subscription_expired', today, null)
          this.$router.push({ name: 'suscripcion' })
        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = endOfSubscription.getTime() - today.getTime();
          let days = Math.ceil(diff / (1000 * 3600 * 24));
          if (days <= 5) {
            this.alert = days;
          }
        }
      }


    },
    getPlanificationWeek() {
      if (this.user.planification) {
        const start = moment(this.user.mainWorkout.start, 'YYYY-MM-DD').toDate();
        const now = moment();
        this.planificationWeek = now.diff(start, 'weeks')
      }
    },
    async getWorkoutsCompleted() {

      const db = getFirestore()
      const user_id = this.$store.state.Auth.token.claims.user_id
      const workoutsRef = collection(db, `workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();
      const q = query(workoutsRef, where("date", ">=", startOfWeek), where("date", "<", endOfWeek),
        where("user_id", "==", user_id)
      );

      const workoutDocs = await getDocs(q);
      if (workoutDocs.empty) {
        //this.workoutDoc = await addDoc(workoutsRef, { weekStart: startOfWeek, day: 0, dates: [] })
        this.workoutsCompleted = 1
        this.activeTab = 0
      } else {

        this.workoutsCompleted = workoutDocs.size || 1
        this.activeTab = workoutDocs.size - 1
        const workoutDates = workoutDocs.docs.map(doc => moment(new Date(doc.data().date.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss'))

        const isSameDay = workoutDates.some(date => moment(date).isSame(moment(), 'day'))
        //check if plan is equal to worked out days and emit and event
        if (this.user.plan == this.workoutsCompleted) {
          this.hideFinish = true;
        } else {
          if (isSameDay) {
            this.hideFinish = true;
            this.workedOut = true
          } else {
            this.hideFinish = false;
            this.workedOut = false
          }
        }
      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff, 0, 0, 0, 0);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59, 999);
      return endOfWeek;
    },
    async getWorkoutPlan() {
      const db = getFirestore();
      let routines = [];

      if (this.user.routines) {
        routines = this.user.routines;

        if (this.user.mainWorkout && this.user.mainWorkout.plan_id) {
          // Add as first element
          routines.unshift(this.user.mainWorkout.plan_id);
          this.user.planification = routines[0];
        }

        if (routines.length > 0) {
          if (routines.length === 1) {
            this.selected = 0;
          }
        } else {
          this.info = 'Sin planificación asignada. Contacta a tu entrenador.';
          return;
        }
      } else {
        this.info = 'Sin planificación asignada. Contacta a tu entrenador.';
        return;
      }

      try {
        // Use Promise.all to handle all routine retrievals in parallel
        const routinesData = await Promise.all(
          routines.map(async (routine) => {
            const planificationsRef = doc(db, `planifications/${routine}`);
            const planificationDoc = await getDoc(planificationsRef);
            if (!planificationDoc.exists()) {
              this.info = 'Planificación no encontrada. Contacta a tu entrenador.';
              return null;
            }

            const data = planificationDoc.data();

            if (data.type.includes('matrix')) {
              const bloques = data.bloques;
              const newBloques = await Promise.all(
                bloques.map(async (bloque, index) => {
                  if (bloque.reference) {
                    const referencePlanification = doc(db, `planifications/${bloque.reference.planificationId}`);
                    const referencePlanificationDoc = await getDoc(referencePlanification);
                    if (!referencePlanificationDoc.exists()) {
                      this.info = 'Planificación no encontrada. Contacta a tu entrenador.';
                      return bloque; // Return the original bloque to avoid breaking the map
                    }
                    const referenceData = referencePlanificationDoc.data();
                    const referenceBloques = referenceData.bloques;
                    return referenceBloques[bloque.reference.blockId] || bloque; // Update the block with the reference or leave it unchanged
                  }
                  return bloque;
                })
              );
              data.bloques = newBloques; // Replace with updated bloques
            }

            return data;
          })
        );

        // Filter out any null responses and add the valid routines
        this.allRoutines = routinesData.filter((routineData) => routineData !== null);

        // Reduce the tags after all routines have been fetched
        const reducedTags = this.allRoutines
          .map((r) => r.tag)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index);

        this.reducedTags = reducedTags.sort();

        if (this.user.planification) {
          this.getPlanificationWeek();
        }
      } catch (error) {
        console.error('Error fetching workout plan:', error);
        this.info = 'Ocurrió un error al obtener la planificación. Intenta nuevamente.';
      }
    }
    ,
    startTour() {
      this.$tours['myTour'].start()

    },
    async finishDailyWorkout() {
      this.loadingFinish = true;
      const id = this.$store.state.Auth.token.claims.user_id
      this.hideFinish = true;
      this.workingOut = false;
      const date = serverTimestamp();

      const obj = {
        date,
        plan_id: this.user.planification,
        plan_name: this.allRoutines[this.selected].name,
        plan_type: this.allRoutines[this.selected].type,
        plan_color: this.allRoutines[this.selected].color,
        plan_block: this.allRoutines[this.selected].bloques,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };

      const db = getFirestore();
      const collectionRef = collection(db, `users/${id}/workouts`);
      await addDoc(collectionRef, obj);

      //check if plan is equal to worked out days and emit and event
      if (this.user.plan == this.workoutsCompleted + 1) {
        this.$notify({
          group: 'feedback',
          title: 'Exito',
          text: 'Objetivo semanal completado',
          type: 'success'
        });
      } else {
        this.$notify({
          group: 'feedback',
          title: 'Exito',
          text: 'Entrenamiento registrado',
          type: 'success'
        });
      }


      this.loadingFinish = false;
      this.workedOut = true;
      this.info = null;

    },
    selectPlan(iterIndex, planElem) {
      this.selected = iterIndex;
      this.activeTab = 0;
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
      }
      )
    },
    async setAlert(type, date, description) {
      const db = getFirestore();

      let user_id = this.$store.state.Auth.token.claims.user_id

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: user_id,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        const alertRef = collection(db, `users/${user_id}/alerts`);
        await addDoc(alertRef, newAlert);


      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },


  },
  computed: {
    filteredRoutines() {
      return this.allRoutines.filter(routine => {
        if (this.filterTags.length === 0) return true;
        return routine.tag.some(tag => this.filterTags.includes(tag));
      });
    }
  },
  async mounted() {
    this.$vuetify.goTo(0);

    await this.getUser();
    await this.getWorkoutsCompleted();
    await this.getWorkoutPlan();

    this.loading = false;

  },
}
</script>

<style>
#big .v-skeleton-loader__image {
  height: 90dvh !important;
}

#plan-view .active {
  box-shadow: 0px 0px 8px 3px var(--v-anchor-base) !important;
  padding: 4px !important;
}

.v-step__header {
  background-color: var(--v-anchor-base) !important;
}

.v-step__arrow--dark:before {
  border-color: var(--v-anchor-base) !important;
  background: var(--v-anchor-base) !important;
}

.v-step {
  background: #272727 !important;
  max-width: 288px !important;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 4px rgb(13 176 237 / 48%) !important;
  -webkit-box-shadow: 0 0 0 4px rgb(13 176 237 / 48%);
}

.evenly-spaced-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* Evenly spaced vertically */
  height: 100%;
  /* Make sure it spans the full height of its parent */
}
</style>


<style scoped></style>